<!--//主页-->
<template>
  <div>
    <!--    头部组件-->
    <Newhead/>
    <!--    视频组件-->
    <videoa />
    <div class="product_left">
      <p class="product_p_title">石材产品</p>
      <div class="product_p_text">
        <p>泛腾拥有数十种石材产品，</p>
        <p style="margin-top: 10px">尽可能的让设计呈现最完美效果。</p>
      </div>
    </div>
    <div class="product_right">
      <div class="stone">
        <p style="font-size: 20px">大理石</p>
        <div>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic1:pic1,picfz1:picfz1}" @mouseenter="modal1" @mouseleave="modalfz" src="@/public/PTM-B001.png" alt="" />
            </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            深啡网
          </p>
<div class="scale">
          <img style="transition: all 3s" :class="{pic2:pic2,picfz2:picfz2}" @mouseenter="modal2" @mouseleave="modalfz" class="pic" src="@/public/PTM-W001.png" alt="" />
  </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            白钻
          </p>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic3:pic3,picfz3:picfz3}" @mouseenter="modal3" @mouseleave="modalfz" class="pic" src="@/public/PTM-Y001.png" alt="" />
              </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            霍依米黄
          </p>
        </div>
      </div>
      <div class="stone">
        <p style="font-size: 20px">石英石</p>
        <div>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic4:pic4,picfz4:picfz4}" @mouseenter="modal4" @mouseleave="modalfz" class="pic" src="@/public/PTQ-G003.png" alt="" />
            </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            古堡灰
          </p>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic5:pic5,picfz5:picfz5}" @mouseenter="modal5" @mouseleave="modalfz" class="pic" src="@/public/PTQ-W003.png" alt="" />
             </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            东方白玉
          </p>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic6:pic6,picfz6:picfz6}" @mouseenter="modal6" @mouseleave="modalfz" class="pic" src="@/public/PTQ-B003.png" alt="" />
            </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            黑金砂
          </p>
        </div>
      </div>
      <div class="stone">
        <p style="font-size: 20px">无机石</p>
        <div>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic7:pic7,picfz7:picfz7}" @mouseenter="modal7" @mouseleave="modalfz" class="pic" src="@/public/PTW-W001.png" alt="" />
             </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            玲珑白（小）
          </p>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic8:pic8,picfz8:picfz8}" @mouseenter="modal8" @mouseleave="modalfz" class="pic" src="@/public/PTW-B001.png" alt="" />
            </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            黑松露
          </p>
          <div class="scale">
          <img style="transition: all 3s" :class="{pic9:pic9,picfz9:picfz9}" @mouseenter="modal9" @mouseleave="modalfz" class="pic" src="@/public/PTW-Y001.png" alt="" />
            </div>
          <p style="text-align: center; margin-top: 15px; margin-bottom: 30px">
            白砂
          </p>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div style="display: inline-block; vertical-align: top">
        <p class="advantage_p">合作优势</p>
      </div>
      <div style="display: inline-block; width: 50%">
        <div>
          <img
            style="
              display: inline-block;
              margin-top: 100px;
              width: 60px;
              height: 60px;
              margin-left: 100px;
            "
            src="@/public/merit_one.png"
            alt=""
          />
          <div
            style="
              margin-left: 30px;
              margin-top: 100px;
              width: 400px;
              display: inline-block;
            "
          >
            <p style="padding-bottom: 20px; color: azure; text-align: left">
              我们拥有充足和独有的天然石材资源、强大的联盟阵线和
            </p>
            <p style="color: azure; text-align: left">一站式的优质服务。</p>
          </div>
        </div>
        <div>
          <img
            style="
              display: inline-block;
              margin-top: 100px;
              width: 60px;
              height: 60px;
              margin-left: 100px;
            "
            src="@/public/merit_two.png"
            alt=""
          />
          <div
            style="
              margin-left: 30px;
              margin-top: 100px;
              width: 400px;
              display: inline-block;
            "
          >
            <p style="padding-bottom: 20px; color: azure; text-align: left">
              我们拥有40年对天然石材的专业知识，不仅对其深入了解，
            </p>
            <p style="color: azure; text-align: left">
              更对天然石原料及其作用进行广泛的拓展。
            </p>
          </div>
        </div>
        <div>
          <img
            style="
              display: inline-block;
              margin-top: 100px;
              width: 60px;
              height: 60px;
              margin-left: 100px;
            "
            src="@/public/merit_three.png"
            alt=""
          />
          <div
            style="
              margin-left: 30px;
              margin-top: 100px;
              width: 400px;
              display: inline-block;
            "
          >
            <p style="padding-bottom: 20px; color: azure; text-align: left">
              我们拥有专业的技术及优秀的团队，针对项目工程使用，
            </p>
            <p style="color: azure; text-align: left">
              从选材下单到售后交付，能为你提供一站式服务。
            </p>
          </div>
        </div>
      </div>
    </div>
    <div style="display: inline-block; vertical-align: top">
      <p class="merit_p">项目亮点</p>
    </div>
    <div style="display: inline-block; width: 50%"></div>

    <div >
        <el-image
        style="width: 25%"
        :src="require('@/public/subway_one.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/subway_two.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/subway_three.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_one.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_two.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_three.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_four.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_five.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_six.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_seven.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_eight.jpg')"
        lazy
      ></el-image>
      <el-image
        style="width: 25%"
        :src="require('@/public/shop_nine.jpg')"
        lazy
      ></el-image>
    </div>
    <Footer/>
  </div>
</template>
<style>
.scale{
  overflow: hidden;
  width: 150px;
  height: 150px;
}
.advantage_p {
  margin-top: 80px;
  line-height: 75px;
  font-weight: bold;
  height: 75px;
  width: 250px;
  border: 2px solid coral;
  font-size: 25px;
  color: azure;
}
.merit_p {
  margin-bottom: 80px;
  margin-top: 80px;
  line-height: 75px;
  font-weight: bold;
  height: 75px;
  width: 250px;
  border: 2px solid coral;
  font-size: 25px;
}
.advantage {
  background-image: url("../../src/public/new_colour.jpg");
  height: 600px;
}
.product_p_title {
  line-height: 75px;
  font-weight: bold;
  margin: auto;
  height: 75px;
  border: 2px solid coral;
  margin-top: 80px;
  font-size: 25px;
  width: 250px;
}

.product_left {
  display: inline-block;
  vertical-align: top;
}
.product_p_text {
  text-align: left;
}
.product_right {
  width: 50%;
  display: inline-block;
  vertical-align: top;
}
.stone {
  display: inline-block;
  margin-top: 20px;
  text-align: left;
  font-size: 20px;
  margin-right: 50px;
}
.pic1,.pic2,.pic3,.pic4,.pic5,.pic6,.pic7,.pic8,.pic9 {
  transform: scale(1);
  width: 150px;
  height: 150px;
}
.picfz1,.picfz2,.picfz3,.picfz4,.picfz5,.picfz6,.picfz7,.picfz8,.picfz9{
  transform: scale(2);
  width: 150px;
  height: 150px;
}

</style>

<script>
import videoa from "../components/video";
import Newhead from "@/components/Newhead";
import Footer from "@/components/Footer";
export default {
  name: "HomeView",
  components: {
    Newhead,
    videoa,
    Footer,
  },
  data() {
    return {
      pic1: true,
      picfz1: false,
       pic2: true,
      picfz2: false,
       pic3: true,
      picfz3: false,
       pic4: true,
      picfz4: false,
       pic5: true,
      picfz5: false,
       pic6: true,
      picfz6: false,
       pic7: true,
      picfz7: false,
         pic8: true,
      picfz8: false,
         pic9: true,
      picfz9: false,
    };
  },
  methods: {
    modal1(){
      console.log(123);
      this.pic1 = false;
      this.picfz1 = true;
    },
        modal2(){
      this.pic2 = false;
      this.picfz2 = true;
    },
        modal3(){
      this.pic3 = false;
      this.picfz3 = true;
    },
        modal4(){
      this.pic4 = false;
      this.picfz4 = true;
    },
        modal5(){
      this.pic5 = false;
      this.picfz5 = true;
    },
    modal6() {
      this.pic6 = false;
      this.picfz6 = true;
    },
    modal7() {
      this.pic7 = false;
      this.picfz7 = true;
    },
        modal8(){
      this.pic8 = false;
      this.picfz8 = true;
    },
        modal9(){
      this.pic9 = false;
      this.picfz9 = true;
    },
    modalfz(){
      this.pic1 = true;
      this.picfz1 = false;
       this.pic2 = true;
      this.picfz2 = false;
       this.pic3 = true;
      this.picfz3 = false;
       this.pic4 = true;
      this.picfz4 = false;
       this.pic5 = true;
      this.picfz5 = false;
       this.pic6 = true;
      this.picfz6 = false;
       this.pic7 = true;
      this.picfz7 = false;
       this.pic8 = true;
      this.picfz8 = false;
       this.pic9 = true;
      this.picfz9 = false;
    },
  },
  created() {
    document.title = 'Pan Ten';
  },
};
</script>
