import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import Stones from "../views/Stones";
import Abouts from "@/views/Abouts";
import Pictures from "@/views/Pictures";
import Technology from "@/views/Technology";
import Classification from "@/views/Classification";
import Install from "@/views/Install";
import Case from "@/views/Case";
import Certificate from "@/views/Certificate";
import Test from "@/views/Test";
import Details from "@/views/Details";


const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}



Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
  },
  {
    path: "/stones/details",
    name: "Details",
    component: Details,
  },
  {
    path: "/stones",
    name: "stones",
    component: Stones,
  },
  {
    path: "/abouts",
    name: "abouts",
    component: Abouts,
  },
  {
    path: "/pictures",
    name: "pictures",
    component: Pictures,
  },
  {
    path: "/technology",
    name: "technology",
    component: Technology,
  },
  {
    path: "/technology/classification",
    name: "classification",
    component: Classification,
  },
      {
    path: "/technology/install",
    name: "install",
    component: Install,
  },
  {
    path: "/technology/case",
    name: "case",
    component: Case,
  },
  {
    path: "/technology/certificate",
    name: "certificate",
    component: Certificate,
  },
  {
    path: "/test/test",
    name: "test",
    component: Test,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
