<template>
<div>
  <div ><h6 class="title">{{msg.data.stone_title}}</h6></div>
  <div>
  <el-carousel :interval="4000" type="card" height="200px">
    <el-carousel-item v-for="item in image" :key="item">
      <img :src="item" alt="">
    </el-carousel-item>
  </el-carousel></div>

 <div ><h1 class="title_brief">{{msg.data.stone_brief}}</h1></div>
</div>


</template>

<script>
export default {
  name: "Stone",
   data() {
    return {
      msg:this.$route.query.msg,
      image:[],
    };
  },
  created() {
    this.image = this.$route.query.image
  },
};
</script>

<style scoped>
.title{
  font-size: 40px;
  margin-bottom: 30px;
  margin-top: 30px;
}
.title_brief{
   font-size: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  font-family: 楷体;
}
 .el-carousel__item h3 {
    color: #475669;
    font-size: 14px;
    opacity: 0.75;
    line-height: 200px;
    margin: 0;
  }

  .el-carousel__item:nth-child(2n) {
    background-color: #99a9bf;
  }

  .el-carousel__item:nth-child(2n+1) {
    background-color: #d3dce6;
  }
</style>