<template>
  <div>
    <div

      style="
        /*overflow: hidden;*/
        width: 150px;
        background-color: red;
        margin: auto;
      "
    >
      <img :class="{ scale: scale }" @mouseenter="modal" class="pic" src="@/public/PTQ-G003.png" alt="" style="transition: all 3s" />
    </div>
  </div>
</template>

<script>
export default {
  name: "Test",
  data() {
    return {
      scale: false,
    };
  },
  methods: {
    modal() {
      this.scale = true;
    },
  },
};
</script>

<style scoped>

.scale {
  transform: scale(3);
}
</style>
