<!--// 技术支持 证书展示页面-->
<template>
  <div>
  <Newhead/>
  <div class="technologycertificate"></div>
    <div  style="display: inline-block;vertical-align: top">
      <p class="product_p_title" >下载链接</p>
    </div>
    <div style="width: 70%;display: inline-block;margin-top: 180px;">
      <ul style="display: inline-block;vertical-align: top">
        <li @click="download('PTM-B001')">深啡网(PTM-B001)
</li>
        <li @click="download('PTM-G001')">爱马仕灰(PTM-G001)
</li>
         <li @click="download('PTM-G002')">霸王花(PTM-G002)
</li>
        <li @click="download('PTM-G003')">雅士灰(PTM-G003)
</li>
        <li @click="download('PTM-G004')">西西里灰(PTM-G004)
</li>
        <li @click="download('PTM-G005')">新锦玉(PTM-G005)
</li>
        <li @click="download('PTM-G006')">风云灰(PTM-G006)
</li>
        <li @click="download('PTM-G007')">罗马灰(PTM-G007)</li>
        <li @click="download('PTM-G008')">古堡灰(PTM-G008)</li>
        <li @click="download('PTM-G009')">微晶灰(PTM-G009)</li>
        <li @click="download('PTM-W001')">白钻(PTM-W001)</li>
        <li @click="download('PTM-W002')">超白钻(PTM-W002)</li>
        <li @click="download('PTM-W003')">羊脂白(PTM-W003)
</li>
        <li @click="download('PTM-W004')">牧羊白(PTM-W004)
</li>
        <li @click="download('PTM-W005')">东方白玉(PTM-W005)
</li>
        <li @click="download('PTM-Y001')">霍伊米黄(PTM-Y001)
</li>
         <li @click="download('PTM-Y002')">洛克米黄(PTM-Y002)
</li>
        <li @click="download('PTM-Y003')">浅啡网(PTM-Y003)
</li>
        <li @click="download('PTM-Y004')">阿曼米黄(PTM-Y004)
</li>
      </ul>
      <ul style="display: inline-block; vertical-align: top">
         <li @click="download('PTQ-B003')">黑金砂(PTQ-B003)
</li>
         <li @click="download('PTQ-B004')">黑白根(PTQ-B004)
</li>
                <li @click="download('PTQ-B006')">黑金花(PTQ-B006)
</li>
           <li @click="download('PTQ-G003')">古堡灰(PTQ-G003)
</li>
        <li @click="download('PTQ-G005')">特菲力灰(PTQ-G005)
</li>
        <li @click="download('PTQ-W001')">白钻(PTQ-W001)
</li>
        <li @click="download('PTQ-W003')">东方白玉(PTQ-W003)
</li>
        <li @click="download('PTQ-W004')">鱼肚白(PTQ-W004)
</li>
        <li @click="download('PTQ-W005')">雪花白(PTQ-W005)
</li>
        <li @click="download('PTQ-W006')">羊脂白(PTQ-W006)
</li>
      </ul>
      <ul style="display: inline-block;vertical-align: top">
         <li @click="download('PTW-B002')">黑钻(PTW-B002)
</li>
        <li @click="download('PTW-G001')">维纳斯灰(浅)(PTW-G001)
</li>
          <li @click="download('PTW-G003')">玲珑灰(浅)(PTW-G003)
</li>
        <li @click="download('PTW-W001')">玲珑白(小)(PTW-W001)
</li>
         <li @click="download('PTW-W002')">白钻(PTW-W002)
</li>
        <li @click="download('PTW-W003')">玲珑白(大)(PTW-W003)
</li>
        <li @click="download('PTW-W005')">五彩缤纷(PTW-W005)
</li>
      </ul>
    </div>

<div style="margin-top: 10px"><Footer/></div>
    </div>

</template>
<style>
.technologycertificate{
  height:600px;
  background: url("../../src/public/Technologycertificate.png") no-repeat ;
  background-size: 100%;
  background-attachment: fixed;
}

</style>
<script>

import Newhead from "@/components/Newhead";
import Footer  from "@/components/Footer";
export default {
  name: "Certificate",
  components: {
    Newhead,
    Footer,
  },
  data() {
    return {
    };
  },
  methods: {
    // 点击下载文件
    download(e) {
      this.$axios({
        url: this.$settings.base_url + "downloadPDFcertificate/",
        method: "post",
        responseType: "blob",
        data: {
          pdf_name_EN: e,
        },
      })
        .then((response) => {
          const blog = new Blob([response.data], { type: "application/pdf" }); //以pdf格式接收后端传回来的二进制数据流
          const url = window.URL.createObjectURL(blog); // 以blob格式将数据流转为URL链接
          const a = document.createElement("a"); // 新建一个a标签
          a.download = ""; // 下载文件命名
          a.href = url; // 给与a标签下载链接
          a.click(); // a标签点击
          this.$notify.closeAll();
        })
        .catch((error) => {});
    },
  },
  created() {

  },
};
</script>