<!--技术支持页面-->
<template>
  <div>
  <!--    头部组件-->
    <Newhead/>
    <div class="technologyclassification"></div>
     <div  style="display: inline-block;vertical-align: top">
      <p class="product_p_title" >人造石分类</p>
    </div>
  <div style="width: 70%;display: inline-block;vertical-align: top;margin-top: 180px" >
<div>
  <img class="stone" src="../public/rockpic.jpg" @click = "rockpic_click" >
  <img class="stone" src="../public/artificialpic.jpg"  @click = "artificialpic_click">
  <img class="stone" src="../public/terrazzopic.jpg"  @click = "terrazzopic_click">
</div>
    </div>
    <div style="margin-top: 10px"> <Footer/> </div>
  </div>
</template>

<script>
import Newhead from "@/components/Newhead";
import Footer from "@/components/Footer";
export default {
  name: "Technologys",
  components: {
    Newhead,
    Footer,
  },
  data(){
    return{
    };
  },
  methods:{
    rockpic_click() {
      this.$router.push({
        path: "/technology/classification/",
        query: { msg: "大理石文件" },
      });
    },
    terrazzopic_click() {
      this.$router.push({
        path: "/technology/classification/",
        query: { msg: "水磨石文件" },
      });
    },
    artificialpic_click() {
      this.$router.push({
        path: "/technology/classification/",
        query: { msg: "石英石文件" },
      });
    },
  },
    created() {
  },
};
</script>

<style scoped>
.technologyclassification{
  height:600px;
  background: url("../public/Classification .jpg") no-repeat ;
  background-size: 100%;
  background-attachment: fixed;
}
</style>
