<!--视频播放组件-->
<template>
  <div>
<!--    <iframe-->
<!--      class="video"-->
<!--      frameborder="0"-->
<!--      src="https://v.qq.com/txp/iframe/player.html?vid=k3504a8yufh"-->
<!--      allowFullScreen="true"-->
<!--    ></iframe>-->
    <video :src= src controls = "controls"  width="70%"></video>
  </div>
</template>

<script>
export default {
  data(){
    return{
      src: require("@/video/plant.mp4")
    }
  },
  methods: {},
};
</script>

<style scoped>

</style>
