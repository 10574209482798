<!--// 技术支持 安装维护文件展示页面-->
<template>
  <div>
    <Newhead />
    <div class="technologyinstall"></div>
    <div style="display: inline-block; vertical-align: top">
      <p class="product_p_title">安装维护建议</p>
    </div>
    <div
      style="
        width: 70%;
        display: inline-block;
        vertical-align: top;
        margin-top: 180px;
      "
    >
      <div style="width: 30%; display: inline-block">
        <img
          class=""
          src="../public/marblemaintain.jpg"
          style="display: inline; background-color: #42b983"
        />
        <p
          @click="downloadpdf('大理石地面维护')"
          style="display: block; margin-top: 30px"
        >
          点击下载中文版
        </p>
        <a href="" style="display: block; margin-top: 30px; margin-bottom: 30px"
          >点击下载英文版</a
        >
      </div>
      <div style="width: 30%; display: inline-block">
        <img
          class=""
          src="../public/quartzmaintain.jpg"
          style="display: inline; background-color: #42b983"
        />
        <p
          @click="downloadpdf('石英石台面维护')"
          style="display: block; margin-top: 30px"
        >
          点击下载中文版
        </p>
        <a href="" style="display: block; margin-top: 30px; margin-bottom: 30px"
          >点击下载英文版</a
        >
      </div>
      <div style="width: 30%; display: inline-block">
        <img
          class=""
          src="../public/installmaintain.jpg"
          style="display: inline; background-color: #42b983"
        />
        <p
          @click="downloadpdf('通用安装建议')"
          style="display: block; margin-top: 30px"
        >
          点击下载中文版
        </p>
        <a href="" style="display: block; margin-top: 30px; margin-bottom: 30px"
          >点击下载英文版</a
        >
      </div>
    </div>
    <div> <Footer/></div>
  </div>
</template>
<style>
.technologyinstall {
  height: 600px;
  background: url("../../src/public/TechnologyInstall.png") no-repeat;
  background-size: 100%;
  background-attachment: fixed;
}
</style>
<script>
import Newhead from "@/components/Newhead";
import Footer from "@/components/Footer";
export default {
  name: "Install",
  components: {
    Newhead,
    // eslint-disable-next-line vue/no-unused-components
    Footer,
  },
  data() {
    return {};
  },
  methods: {
    downloadpdf(e) {
      this.$axios({
        url: this.$settings.base_url + "downloadpdf/",
        method: "post",
        responseType: "blob",
        data: {
          pdf_name_CN: e,
        },
      })
        .then((response) => {
          const blog = new Blob([response.data], { type: "application/pdf" }); //以pdf格式接收后端传回来的二进制数据流
          const url = window.URL.createObjectURL(blog); // 以blob格式将数据流转为URL链接
          const a = document.createElement("a"); // 新建一个a标签
          a.download = ""; // 下载文件命名
          a.href = url; // 给与a标签下载链接
          a.click(); // a标签点击
          this.$notify.closeAll();
        })
        .catch((error) => {});
    },
  },
  created() {},
};
</script>
