<template>

<div>

  <div><Newhead/></div>
<div>
  <div class="pic">
    <div><img @mouseenter="fangdajing()" style="width:250px" :src=this.srcgq alt="">
      <div class="move">
        <img :src=this.srcgq alt="">
      </div>
    </div>
  </div>
  <div style="display: inline-block;width:10%"></div>
  <div class="word" >
    <P style="height: 30px; font-size: 25px; text-align: left;font-weight:bold;margin-left: 30px">{{ this.name }}</P>
  <div style="margin-top: 30px">
    <div style="width: 70%;border-top:solid 1px rgb(128,128,135) ;">
      <P style=" font-size: 15px;  text-align: left;font-weight:bold;margin-left: 30px;margin-top: 30px;">规格板</P>
      <p style="text-align: left;margin-left: 30px;margin-top: 10px;">格式：2700*1800mm</p>
      <p style="text-align: left;margin-left: 30px;margin-top: 10px;">厚度：14mm,16.5mm,17.5mm</p>
    </div>
  </div>
  </div>
</div>
</div>


</template>

<script>
import Newhead from "@/components/Newhead";
import Footer  from "@/components/Footer";
export default {
  name: "Details",
    components: {
    Newhead,
      Footer,
  },
  data(){
      return{
        srcgq:null,
        name:null,
      };
  },
  methods:{
    fangdajing(){
      const pic = document.querySelector(".pic");
      const move = document.querySelector(".move");
      const bigimg = document.querySelector(".move img");
      pic.addEventListener("mousemove", function (e) {
        // 获取鼠标距离左侧的偏移
        let x = e.clientX;
        // 获取pic距离左侧的便宜
        let l = pic.offsetLeft;
        // 计算最终的left值
        let moveleft = x - l - move.offsetWidth / 2;
        // 边界检测
        if (moveleft <= 0) {
          moveleft = 0;
        }
        //最大值获取
        let maxleft = pic.offsetWidth - move.offsetWidth;
        if (moveleft >= maxleft){
         moveleft =  maxleft;
        }
        // 设置样式
          move.style.left = moveleft + "px";
        // 计算大图的left值
        let bigimgleft = (moveleft+move.offsetWidth/2)/pic.offsetWidth*bigimg.offsetWidth-move.offsetWidth/2;
        // 设置left值
        bigimg.style.left = -bigimgleft + "px";



        // 获取鼠标距离左侧的偏移
        let y = e.clientY;
        // 获取pic距离左侧的便宜
        let t = pic.offsetTop;
        // 计算最终的left值
        let movetop = y - t - move.offsetHeight / 2;
        // 边界检测
        if (movetop <= 0) {
          movetop = 0;
        }
        //最大值获取
        let maxtop = pic.offsetHeight - move.offsetHeight;
        if (movetop >= maxtop){
         movetop =  maxtop;
        }
        // 设置样式
          move.style.top = movetop + "px";
        // 计算大图的left值
        let bigimgtop = (movetop+move.offsetHeight/2)/pic.offsetHeight*bigimg.offsetHeight-move.offsetHeight/2;
        // 设置left值
        bigimg.style.top = -bigimgtop + "px";
      })
    },
  },
  created() {
        // 接受Newhead传回来的参数
    this.srcgq = this.$route.query.srcgq
    this.name = this.$route.query.name
  },

watch: {
    $route() {
        //这样就可以获取到变化的参数了，然后执行参数变化后相应的逻辑就行了
        this.srcgq = this.$route.query.srcgq
    this.name = this.$route.query.name
    }
  },
}
</script>

<style scoped>
.move img{
  position: absolute;
  left:0;
  top:0;
  width: 500px;
}
.move{
  overflow: hidden;
  width:80px;
  height: 80px;
  border-radius: 50%;
  border: solid 2px #333333;
  position: absolute;
  left:0;
  top:0;

}
.pic{
  display: inline-block;
  margin-top: 120px;
  position: relative;
}
.word{
  display: inline-block;
  width: 50%;
  height: 300px;
  margin-top: 120px;
  vertical-align: top;
}
</style>