<!--//人造石文件展示页面-->
<template>
  <div >
    <img :src="this.technology_file" style="width: 50%;">
  </div>
</template>
<style>
</style>
<script>


export default {
  name: "Classification",
  components: {
  },
  data() {
    return {
      technology_name:null,
      technology_file:null,
    };
  },
  methods: {
    select_stone() {
      this.$axios({
        url: this.$settings.base_url + "technology/",
        method: "post",
        data: {
          technology_name: this.technology_name,
        },
      })
        .then((response) => {
          this.technology_file = this.$settings.base_url+"media/"+response.data.data.image;
        })
        .catch((error) => {});
    },

  },
  created() {
    this.technology_name = this.$route.query.msg;
    this.select_stone();
  },
};
</script>