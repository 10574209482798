<!--//图库渲染页面-->
<template>
  <div>
    <div>
    <!--    头部组件-->
      <Newhead/>
  </div>
    <div>
      <p>即将上线</p>
    </div>
    </div>
</template>
<style>

</style>
<script>
import Newhead from "@/components/Newhead";

export default {
  name: "Pictures",
  components: {
    Newhead,
  },
  data() {
    return {
    };
  },
  methods: {

  },
  created() {},
};
</script>