<!--//关于泛腾渲染页面-->
<template>
  <div>

    <div>
      <!--    头部组件-->

      <Newhead/>
    </div>
      <div class="attachment-pic"></div>
      <div class="brand"><div style="height:0.5px;"></div>
        <p class="brand-p">品 牌</p>
        <p style="margin-top: 10px">
          泛腾属于骏杰集团旗下的人造石品牌，背靠骏杰集团
        </p>
        <p style="margin-top: 10px">自有矿山资源及多年的工程交付经验，以准确的工艺，</p>
        <p style="margin-top: 10px">稳健的产能，在每一个生产环节中铸造匠心，将人造</p>
        <p style="margin-top: 10px">石和天然石之精髓进行有效的设计结合。</p>
      </div>

    <div class="truck">
      <el-image :src="require('@/public/truck.jpg')" lazy></el-image>
    </div>
    <p  class="artificial-p1">人 造 石</p>
    <div class="artificial"> <div style="height: 0.5px"></div>
      <p class="artificial-p2">
        人造石是以天然石材粉料、碎料为主要原材料制作成的装饰石材。一般经有机或无机胶粘剂粘结，通过真空或挤压成型而制成装饰面柸（块、板）料，再经切割、铣磨、抛光等工艺，制成装饰用石材。人造石是一种符合节能、节材和资源综合利用政策的具有广泛发展前景的新产品。
      </p>
    </div>


    <p style="margin-top: 80px" class="six-merit">六 大 优 点</p>
    <div class="six-merit_pic">
<div style="height: 0.5px"></div>

<div style="display: inline-block ;margin-left: 80px">
      <div style="margin-top: 120px">
        <img
          style="margin-top:20px; width: 40px; height: 40px;display: inline-block"
          src="../../src/public/virtue_one.png"
        />
        <div style="margin-left: 20px; text-align: left; display: inline-block;vertical-align: top">
        <p style="color: azure ; font-size: 20px">个体设计</p>
        <p style="color: azure ;margin-top: 10px">可塑性高，可加成各种异型</p>
          <p style="color: azure;margin-top: 10px">并可进行个性化设计。</p>
          <br>
          <p style="color: azure;margin-top: 10px"></p>
      </div>
        </div>

    <div style="margin-top: 120px">
        <img
          style="margin-top:20px; width: 40px; height: 40px;display: inline-block"
          src="../../src/public/virtue_four.png"
        />
        <div style="margin-left: 20px; text-align: left; display: inline-block;vertical-align: top">
        <p style="color: azure ; font-size: 20px">安全</p>
        <p style="color: azure ;margin-top: 10px">人造石无毒、无辐射，属于</p>
          <p style="color: azure;margin-top: 10px">安全、环保材料</p>
             <br>
          <p style="color: azure;margin-top: 10px"></p>
      </div>
        </div></div>
<div style="display: inline-block ;margin-left: 80px">
      <div style="margin-top: 120px">
        <img
          style="margin-top:20px; width: 40px; height: 40px;display: inline-block"
          src="../../src/public/virtue_two.png"
        />
        <div style="margin-left: 20px; text-align: left; display: inline-block;vertical-align: top">
        <p style="color: azure ; font-size: 20px">力学性能优秀</p>
        <p style="color: azure ;margin-top: 10px">适合在受力大的拐角、踏步、厨房</p>
          <p style="color: azure;margin-top: 10px">操作台、卫浴间及人流大的商业</p>
          <p style="color: azure;margin-top: 10px">地面等处安装。</p>
      </div>
        </div>

    <div style="margin-top: 120px">
        <img
          style="margin-top:20px; width: 40px; height: 40px;display: inline-block"
          src="../../src/public/virtue_five.png"
        />
        <div style="margin-left: 20px; text-align: left; display: inline-block;vertical-align: top">
        <p style="color: azure ; font-size: 20px">环保</p>
        <p style="color: azure ;margin-top: 10px">可以利用碎石、石材边角料等制造</p>
          <p style="color: azure;margin-top: 10px">，节约自然资源，保护环境，是</p>
          <p style="color: azure;margin-top: 10px">环保、可再生的装饰材料之一。</p>
      </div>
        </div></div>
      <div style="display: inline-block ;margin-left: 80px">
      <div style="margin-top: 120px">
        <img
          style="margin-top:20px; width: 40px; height: 40px;display: inline-block"
          src="../../src/public/virtue_three.png"
        />
        <div style="margin-left: 20px; text-align: left; display: inline-block;vertical-align: top">
        <p style="color: azure ; font-size: 20px">无孔</p>
        <p style="color: azure ;margin-top: 10px">质地密实，没有孔隙，吸水率远</p>
          <p style="color: azure;margin-top: 10px">低于天然石，因为水产生的病变</p>
          <p style="color: azure;margin-top: 10px">比较少。</p>
      </div>
        </div>

    <div style="margin-top: 120px">
        <img
          style="margin-top:20px; width: 40px; height: 40px;display: inline-block"
          src="../../src/public/virtue_six.png"
        />
        <div style="margin-left: 20px; text-align: left; display: inline-block;vertical-align: top">
        <p style="color: azure ; font-size: 20px">可翻新</p>
        <p style="color: azure ;margin-top: 10px">人造石可翻新使用，长时间使用</p>
          <p style="color: azure;margin-top: 10px">磨损后可进行研磨翻新，恢复石</p>
          <p style="color: azure;margin-top: 10px">材崭新的效果。</p>
      </div>
        </div></div>
    </div>

    <p class="six-merit">生 产 周 期</p>
    <div class="period">
      <div style="" :class="{ period_one }">
        <img style="height: 300px" src="../../src/public/period-one.jpg" @mouseenter="period_two" />
      </div>
      <div :class="{ period_two_static, period_two_move }">
        <img style="height: 300px" src="../../src/public/period-two.jpg" @mouseenter="period_three" />
      </div>
    <div :class="{ period_three_static, period_three_move }">
        <img style="height: 300px"
          src="../../src/public/period-three.jpg"
          @mouseenter="period_four"
        />
    </div>
    <div :class="{ period_four_static, period_four_move }"><img style="height: 300px" src="../../src/public/period-four.jpg" @mouseenter="period_five"/></div>
    <div :class="{ period_five_static, period_five_move }"><img style="height: 300px" src="../../src/public/period-five.jpg" @mouseenter="period_six"/></div>
    <div  :class="{ period_six_static, period_six_move }"><img style="height: 300px" src="../../src/public/period-six.jpg" @mouseenter="period_six_"/></div>
      </div>
<div style="margin-top: 10px"><Footer /></div>
  </div>

</template>
<style>
.attachment-pic{
  height: 900px;
  background: url("../../src/public/home.jpg") no-repeat center center;
  background-size: 100% 100%;
  background-attachment: fixed;
}
.brand {
  background-color: rgb(242, 242, 242);
  height: 600px;
}
.brand-p {
  line-height: 75px;
  font-weight: bold;
  width: 250px;
  margin: auto;
  height: 75px;
  border: 2px solid coral;
  font-size: 25px;
  margin-top: 70px;
  margin-bottom: 70px;
}
.truck {
  margin-top: -200px;
}
.artificial {
  background-image: url("../../src/public/artificial.jpg");
  background-size: 100% 100%;
  height: 600px;
}
.artificial-p1 {
  line-height: 75px;
  font-weight: bold;
  width: 250px;
  margin: auto;
  height: 75px;
  border: 2px solid coral;
  margin-bottom: 80px;
  margin-top: 80px;
  font-size: 25px;
}
.artificial-p2 {
  width: 450px;
  color: azure;
  line-height: 30px;
  margin: auto;
  margin-top: 90px;
}
.six-merit {
  line-height: 75px;
  font-weight: bold;
  width: 250px;
  margin: auto;
  height: 75px;
  border: 2px solid coral;
  margin-top: 80px;
  margin-bottom: 80px;
  font-size: 25px;
}
.six-merit_pic{
  background-image: url("../../src/public/six-merit.jpg");
  background-size: 100% 100%;
  height: 600px;
}
.period_one {
  display: inline-block;
}
.period_two_move{
  margin-left: -400px;
  display: inline-block;
  border-left: 20px solid white;
}
.period_two_static{
  display: inline-block;
  border-left: 20px solid white;
}
.period_three_static{
  display: inline-block;
  border-left: 20px solid white;
}
.period_three_move{
  display: inline-block;
  margin-left: -400px;
  border-left: 20px solid white;
}
.period_four_static{
  display: inline-block;
  border-left: 20px solid white;
}
.period_four_move{
  display: inline-block;
  margin-left: -400px;
  border-left: 20px solid white;
}
.period_five_static{
  display: inline-block;
  border-left: 20px solid white;
}
.period_five_move{
  display: inline-block;
  margin-left: -400px;
  border-left: 20px solid white;
}
.period_six_static{
  display: inline-block;
  border-left: 20px solid white;
}
.period_six_move{
  display: inline-block;
  margin-left: -400px;
  border-left: 20px solid white;
}
</style>
<script>
import Footer from "@/components/Footer";
import Newhead from "@/components/Newhead";

export default {
  name: "Abouts",
  components: {
    Newhead,
    Footer,
  },
  data() {
    return {
      period_one: true,
      period_two_static: false,
      period_two_move: true,
      period_three_static: false,
      period_three_move: true,
      period_four_static: false,
      period_four_move: true,
      period_five_static: false,
      period_five_move: true,
      period_six_static: false,
      period_six_move: true,
    };
  },
  methods: {
    period_six_() {
      this.period_six_static = false;
      this.period_six_move = true;
    },
    period_six(){
      this.period_two_static = false;
      this.period_two_move = true;
      this.period_three_static = false;
      this.period_three_move = true;
      this.period_four_static = false;
      this.period_four_move = true;
      this.period_five_static = false;
      this.period_five_move = true;
       this.period_six_static = true;
      this.period_six_move = false;
    },
    period_five(){
      this.period_two_static = false;
      this.period_two_move = true;
      this.period_three_static = false;
      this.period_three_move = true;
      this.period_four_static = false;
      this.period_four_move = true;
      this.period_five_static = true;
      this.period_five_move = false;
      this.period_six_static = false;
      this.period_six_move = true;
    },
    period_four(){
      this.period_two_static = false;
      this.period_two_move = true;
      this.period_three_static = false;
      this.period_three_move = true;
      this.period_four_static = true;
      this.period_four_move = false;
      this.period_five_static = false;
      this.period_five_move = true;
       this.period_six_static = false;
      this.period_six_move = true;
    },
    period_three(){
      this.period_two_static = false;
      this.period_two_move = true;
      this.period_three_static = true;
      this.period_three_move = false;
      this.period_four_static = false;
      this.period_four_move = true;
      this.period_five_static = false;
      this.period_five_move = true;
       this.period_six_static = false;
      this.period_six_move = true;
    },
    period_two() {
      this.period_two_static = true;
      this.period_two_move = false;
      this.period_three_static = false;
      this.period_three_move = true;
      this.period_four_static = false;
      this.period_four_move = true;
      this.period_five_static = false;
      this.period_five_move = true;
      this.period_six_static = false;
      this.period_six_move = true;
    },
  },
  created() {},
};
</script>
