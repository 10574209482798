<!--底部栏组件-->
<template>
<div style="background-color:RGB(135,135,135);height: 300px;margin-top: -4px;">
<div style="display: inline-block;vertical-align: top;margin-top: 60px;margin-right: 60px">
  <p style="font-size: 17px;font-weight: bold">香  港</p>
  <p>地址：香港沙田小沥源西安丽街11号</p>
  <p>企业中心23楼12-15室</p>
  <p>Room12-15, 23Floor,Corporation Park, </p>
  <p>11 On Lai Street,
Sha Tin,N.T.,Hong Kong.</p>
  <p>电话：+852-27079991</p>
  <p>传真：+852-27079300</p>
</div>
  <div style="background-color: azure;width: 1px;height: 250px;display: inline-block;margin-top: 25px"></div>
<div style="display: inline-block;vertical-align: top;margin-top: 60px;margin-right: 60px;margin-left: 60px">
  <p style="font-size: 17px;font-weight: bold">深 圳</p>
  <p>地址：广东省深圳市福田区</p>
  <p>福田南路皇城广场24F</p>
  <p>Room 24F,Huangcheng Building, </p>
  <p>Futian south road,
Shenzhen,Guangdong,China.</p>
  <p>电话：+86-755-83003633</p>
  <p>传真：+86-755-83000933</p>
</div>
  <div style="background-color: azure;width: 1px;height: 250px;display: inline-block"></div>
<div style="display: inline-block;vertical-align: top;margin-top: 60px;margin-left: 60px">
 <p>ICP主体备案号</p>
  <p>粤ICP备2023052826号</p>
  <img src="../public/备案图标.png" style="display: inline-block">
  <p style="display: inline-block;margin-left: 10px"><a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=44030402006267">粤公网安备 44030402006267号</a></p>
</div>
</div>
</template>

<script>
export default {
  methods: {},
};
</script>
<style scoped>
p,a{
  font-weight: lighter;
  font-size: 10px;
  text-align: left;
  margin-bottom: 8px;
  color: azure;
}
</style>