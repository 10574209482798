import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
// 配置全局样式
import '@/assets/global.css'
import settings from "@/js/settings";
Vue.config.productionTip = false;

import axios from "axios";
Vue.prototype.$axios = axios;
Vue.prototype.$settings = settings;
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(ElementUI);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
