<!--// 技术支持 安装维护文件展示页面-->
<template>
  <div>
  <Newhead/>

  <div class="technologycase"></div>
    <div  style="display: inline-block;vertical-align: top">
      <p class="product_p_title" >项目案例</p>
    </div>
    <div style="width: 70%;display: inline-block;overflow-x: hidden;height: 300px;margin-top: 180px;">
      <ul style="display: inline-block;">
        <li>信阳鼎盛广场
</li>
        <li>湖南步步高商场
</li>
         <li>中山利和广场
</li>
        <li>南宁轨道交通1号线
</li>
        <li>成都铁狮门
</li>
        <li>海口喜来登酒店
</li>
        <li>武汉人信汇商业广场
</li>
        <li>南宁华润中心</li>
        <li>浙江余姚五彩城</li>
        <li>杭州华润MT项目</li>
        <li>深圳华润大涌新城花园2期</li>
        <li>杭州万象城尚泰改造项目</li>
        <li>温州华润万象城3标段
</li>
        <li>南通华润万象城
</li>
        <li>泰州华润中心A2地块
</li>
        <li>深圳九方购物中心
</li>
         <li>昆山九方购物中心
</li>
        <li>贵阳万科大都会
</li>
        <li>宁波万科1902广场
</li>
        <li>长安万科大都会
</li>
        <li>徐州苏宁广场
</li>
        <li>中山利和灯博中心
</li>
         <li>深圳卓越宝中时代广场
</li>
        <li>龙岗创投大厦室内装修工程
</li>
        <li>深圳君尚百货
</li>
        <li>深圳怡化科技大厦
</li>
        <li>深圳太平金融中心
</li>
        <li>深圳燃气大厦
</li>
      </ul>
      <ul style="display: inline-block;">
         <li>佛山长华国际中心
</li>
         <li>苏州碧桂园凤凰荟
</li>
                <li>来宾百悦商业广场
</li>
           <li>中山海港城
</li>
        <li>深圳能源大厦
</li>
        <li>深圳塘朗城
</li>
        <li>合肥万科森林公园商业广场
</li>
        <li>深圳宜城风景花园精装修
</li>
        <li>佛山华强广场
</li>
        <li>东莞海德一号广场
</li>
           <li>重庆江北嘴金融城2#
</li>
        <li>重庆财信广场掩体商业
</li>
        <li>深圳益田假日广场
</li>
        <li>西安益田假日广场
</li>
        <li>南宁地铁一号线
</li>
        <li>南宁地铁二号线
</li>
         <li>东莞地铁R2线
</li>
        <li>海口机场购物广场
</li>
        <li>三亚珍珠博物馆
</li>
        <li>贵阳云岩万达广场
</li>
        <li>成都龙湖滨江天街
</li>
        <li>象屿集团自贸时代广场
</li>
           <li>包头居然之家
</li>
        <li>北京居然之家
</li>
        <li>上海SOHO复兴天地
</li>
        <li>常州星河Cocopark
</li>
        <li>青岛奥帆中心
</li>
        <li>营口万隆广场
</li>
      </ul>
      <ul style="display: inline-block;margin-right: -100px">
         <li>广州凯荔花园
</li>
        <li>宁波万象城
</li>
          <li>重庆兰亭新都会
</li>
        <li>深圳深业上城
</li>
         <li>深圳华盛新莎荟
</li>
        <li>长春万豪世纪广场
</li>
        <li>深圳星河上寓
</li>
         <li>盘锦东方时代广场</li>
        <li>兰州军区总医院</li>
        <li>新疆克拉玛依中学</li>
        <li>东莞益田大运城邦（假日天地）</li>
        <li>上海复兴SOHO天地中心</li>
        <li>盘锦东方银座广场</li>
           <li>合肥燃气大厦</li>
        <li>东莞长安万科广场</li>
        <li>杭州华润中心</li>
        <li>重庆东方国际广场</li>
        <li>深圳华润城</li>
        <li>杭州华润万象城改造</li>
         <li>贵阳万科大都会</li>
        <li>遵义新城吾悦广场</li>
        <li>南京龙湖天街</li>
        <li>成都银泰百货</li>
        <li>重庆江北嘴金融城3号</li>
        <li>三亚海棠湾君悦酒店</li>
        <li>铜陵大洋百货</li>
        <li>深圳创投大厦</li>
        <li>深圳创城宜景</li>
      </ul>
    </div>
    <div style="margin-top: 10px">
    <Footer/>
      </div>
    </div>
</template>
<style>
.technologycase{
  height:600px;
  background: url("../../src/public/TechnologyCase.png") no-repeat ;
  background-size: 100%;
  background-attachment: fixed;
}
li {
  text-align: left;
  margin-left: 100px;
  margin-right: 100px;
  padding-bottom: 12px;
}
</style>
<script>

import Newhead from "@/components/Newhead";
import Footer  from "@/components/Footer";
export default {
  name: "Case",
  components: {
    Newhead,
    Footer,
  },
  data() {
    return {
    };
  },
  methods: {

  },
  created() {

  },
};
</script>