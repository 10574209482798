<!-- 头部组件-->
<template>
  <div>
    <div>
      <el-menu
        :default-active="activeIndex"
        class="el-menu-demo"
        mode="horizontal"
        @select="handleSelect"
        background-color="#ffffff"
      >
        <div style="width: 150px" class="navigation">
          <img style="height: 80px" src="../../public/home.png" />
        </div>
        <div
          :class="{ series: series }"
          class="navigation"
          style="width: 100px"
          @mouseenter="
            modal();
            stone_show();
          "
          @click="modal_click"
        >
          系列
        </div>
        <div
          :class="{ aboutnavigation: aboutnavigation }"
          class="navigation"
          style="width: 100px"
          @mouseenter="about"
          @click="about_click"
        >
          关于泛腾
        </div>
        <div
          :class="{ technologynavigation: technologynavigation }"
          class="navigation"
          style="width: 100px"
          @mouseenter="technology"
          @click="technology_click"
        >
          技术支持
        </div>
        <!--        <el-menu-item index="6" >-->
        <!--          <div><img class="head_pic" src="../../public/home.png" alt="" /></div>-->
        <!--        </el-menu-item>-->

        <!--        <el-menu-item index="5" @mouseenter.native="modal" @click="modal_click">-->
        <!--          <span>系列</span>-->
        <!--        </el-menu-item>-->

        <!--        <el-menu-item index="4" @mouseenter.native="about" @click="about_click">-->
        <!--          <span>关于泛腾</span>-->
        <!--        </el-menu-item>-->

        <!--        <el-menu-item-->
        <!--          index="3"-->
        <!--          @mouseenter.native="picture"-->
        <!--          @click="picture_click"-->
        <!--        >-->
        <!--          <span>图库</span>-->
        <!--        </el-menu-item>-->

        <!--        <el-menu-item-->
        <!--          index="2"-->
        <!--          @mouseenter.native="technology"-->
        <!--          @click="technology_click"-->
        <!--        >-->
        <!--          <span>技术支持</span>-->
        <!--        </el-menu-item>-->
        <!--        <el-menu-item index="2"-->
        <!--          ><a href="https://www.baidu.com/" target="_blank"-->
        <!--            >联系我们</a-->
        <!--          ></el-menu-item-->
        <!--        >-->
        <!--        <el-menu-item index="1">-->
        <!--          <el-input-->
        <!--            placeholder="请输入石材全称"-->
        <!--            prefix-icon="el-icon-search"-->
        <!--            v-model="stone_title"-->
        <!--            @keyup.enter.native="func"-->
        <!--          >-->
        <!--          </el-input-->
        <!--        ></el-menu-item>-->
      </el-menu>
    </div>

    <!--    <div>-->
    <!--      <el-menu-->
    <!--        :default-active="activeIndex"-->
    <!--        class="el-menu-demo"-->
    <!--        mode="horizontal"-->
    <!--        @select="handleSelect"-->
    <!--        background-color="#ffffff"-->
    <!--      >-->
    <!--        <el-menu-item index="6" >-->
    <!--          <div><img class="head_pic" src="../../public/home.png" alt="" /></div>-->
    <!--        </el-menu-item>-->

    <!--        <el-menu-item index="5" @mouseenter.native="modal" @click="modal_click">-->
    <!--          <span>系列</span>-->
    <!--        </el-menu-item>-->

    <!--        <el-menu-item index="4" @mouseenter.native="about" @click="about_click">-->
    <!--          <span>关于泛腾</span>-->
    <!--        </el-menu-item>-->

    <!--        <el-menu-item-->
    <!--          index="3"-->
    <!--          @mouseenter.native="picture"-->
    <!--          @click="picture_click"-->
    <!--        >-->
    <!--          <span>图库</span>-->
    <!--        </el-menu-item>-->

    <!--        <el-menu-item-->
    <!--          index="2"-->
    <!--          @mouseenter.native="technology"-->
    <!--          @click="technology_click"-->
    <!--        >-->
    <!--          <span>技术支持</span>-->
    <!--        </el-menu-item>-->
    <!--        <el-menu-item index="2"-->
    <!--          ><a href="https://www.baidu.com/" target="_blank"-->
    <!--            >联系我们</a-->
    <!--          ></el-menu-item-->
    <!--        >-->
    <!--        <el-menu-item index="1">-->
    <!--          <el-input-->
    <!--            placeholder="请输入石材全称"-->
    <!--            prefix-icon="el-icon-search"-->
    <!--            v-model="stone_title"-->
    <!--            @keyup.enter.native="func"-->
    <!--          >-->
    <!--          </el-input-->
    <!--        ></el-menu-item>-->
    <!--      </el-menu>-->
    <!--    </div>-->

    <!--    系列模态框-->
    <div
      v-show="modalstone_isshow"
      @mouseleave="clearall"
      @mouseenter.native="modal"
    >
      <div id="modal" class="modal_type">
        <p
          class="stone-type"
          :class="{ quartz_stone: quartz_stone }"
          @click="
            quartzstone_click();
            select_stone();
          "
        >
          石英石
        </p>
        <p
          class="stone-type"
          :class="{ terrazzo: terrazzo }"
          @click="
            terrazzo_click();
            select_stone();
          "
        >
          无机石
        </p>
        <p
          class="stone-type"
          :class="{ granite: granite }"
          @click="
            granite_click();
            select_stone();
          "
        >
          大理石
        </p>
        <p
          class="stone-type"
          :class="{ quartz: quartz }"
          @click="
            quartz_click();
            select_stone();
          "
        >
          石英砖
        </p>
        <!--      <p-->
        <!--        class="stone-type"-->
        <!--        :class="{ clay: clay }"-->
        <!--        @click="-->
        <!--          clay_click();-->
        <!--          select_stone();-->
        <!--        "-->
        <!--      >-->
        <!--        泥客石-->
        <!--      </p>-->
      </div>
      <div id="modal" class="modal_color">
        <p
          class="stone-color"
          :class="{ WhiteColor: WhiteColor }"
          @click="
            whitecolor_click();
            select_stone();
          "
        >
          白色
        </p>
        <p
          class="stone-color"
          :class="{ GrayColor: GrayColor }"
          @click="
            gray_color();
            select_stone();
          "
        >
          灰色
        </p>
        <p
          class="stone-color"
          :class="{ YellowColor: YellowColor }"
          @click="
            yellow_color();
            select_stone();
          "
        >
          黄色
        </p>
        <p
          class="stone-color"
          :class="{ BlackColor: BlackColor }"
          @click="
            black_color();
            select_stone();
          "
        >
          黑色
        </p>
      </div>
      <div id="modal" class="modal_pic">

        <img
          class="home_pic"
          v-for="bi in stone_info"
          :src="url + bi.image"
          :srcgq="url + bi.image_download"
          :name="bi.stone_chinaname"
          :encode="bi.stone_encode"
          @mouseenter="pic_display($event)"
          @click="pic_detail($event)"
          alt=""
          style="width: 45px; height: 45px"
        />
      </div>
      <div id="modal" class="modal_details">
        <img :class="{ pic_dispaly }" :src="stone_src" alt="" />
        <p class="font_display">{{ stone_chinaname }}</p>
        <p class="font_display" @click="download">点此下载</p>
      </div>
    </div>
    <!--    关于泛腾模态框-->
    <div
      id="pic"
      @mouseleave="clearall"
      v-show="about_isshow"
      @mouseenter.native="about"
    >
      <div class="icon">
        <img class="brand_icon" src="../../src/public/brand.png" alt="" />
        <p class="text">品牌</p>
      </div>
      <div class="icon">
        <img class="brand_icon" src="../../src/public/stone.png" alt="" />
        <p class="text">人造石介绍</p>
      </div>
      <div class="icon">
        <img class="brand_icon" src="../../src/public/six.png" alt="" />
        <p class="text">六大优点</p>
      </div>
      <div class="icon">
        <img class="brand_icon" src="../../src/public/period.png" alt="" />
        <p class="text">生产周期</p>
      </div>
    </div>
    <!--    大图库-->
    <div
      id="pic"
      @mouseleave="clearall"
      v-show="picture_isshow"
      @mouseenter.native="picture"
    >
      <div class="pic_icon">项目亮点</div>
      <div class="pic_icon">项目亮点</div>
      <div class="pic_icon">项目亮点</div>
    </div>
    <!--    技术支持-->
    <div
      id="pic"
      @mouseleave="clearall"
      v-show="technology_isshow"
      @mouseenter.native="technology"
    >
      <div class="icon" @click="technology_click">
        <img
          class="brand_icon"
          src="../../src/public/classification.png"
          alt=""
        />
        <p class="text">人造石分类</p>
      </div>
      <div class="icon" @click="install_click">
        <img class="brand_icon" src="../../src/public/install.png" alt="" />
        <p class="text">安装维护建议</p>
      </div>
      <div class="icon" @click="certificate_click">
        <img class="brand_icon" src="../../src/public/certificate.png" alt="" />
        <p class="text">证书</p>
      </div>
      <div class="icon" @click="case_click">
        <img class="brand_icon" src="../../src/public/case.png" alt="" />
        <p class="text">项目案例</p>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "axios";
export default {
  name: "Head",
  data() {
    return {
      technologynavigation: false, // 技术支持导航栏
      aboutnavigation: false, // 关于导航栏
      series: false, // 系列导航栏
      modalstone_isshow: false, // 显示系列模态框
      about_isshow: false, // 显示关于模态框
      picture_isshow: false, // 显示图库模态框
      technology_isshow: false, //显示技术支持模态框
      stone_title: "", //用户输入的石材名称
      url: this.$settings.base_url + "media/", // 第四联石材后端图片位置
      pic_dispaly: false, // 第四联图片是否渲染
      quartz_stone: false, // 石英石是否显示选取色
      terrazzo: false, // 水磨是否显示选取色
      quartz: false, // 石英砖是否显示选取色
      granite: false, // 岗石是否显示选取色
      clay: false, // 泥客石是否显示选取色
      WhiteColor: false, // 白色系是否显示选取色
      GrayColor: false, // 灰色系是否显示选取色
      YellowColor: false, // 黄色系是否显示选取色
      BlackColor: false, // 黑色系是否显示选取色
      stone_type: null, // 向后端发送石材类型的字段
      stone_color: null, // 向后端发送石材颜色的字段
      stone_info: null, // 储存后端传回来的石材模型，用于循环数据展示
      stone_src: null, // 储存第四联石材图片地址
      stone_chinaname: null, // 储存第四联石材图片中文名
      stone_encode: null,
    };
  },
  methods: {
    // 清除所有模态框
    clearall() {
      this.technologynavigation = false;
      this.aboutnavigation = false;
      this.series = false;
      this.modalstone_isshow = false;
      this.picture_isshow = false;
      this.about_isshow = false;
      this.technology_isshow = false;
    },
    // 显示系列模态框
    modal() {
      this.technologynavigation = false;
      this.aboutnavigation = false;
      this.series = true;
      this.modalstone_isshow = true;
      this.picture_isshow = false;
      this.about_isshow = false;
      this.technology_isshow = false;
    },
    // 显示关于XX模态框
    about() {
      this.technologynavigation = false;
      this.aboutnavigation = true;
      this.series = false;
      this.about_isshow = true;
      this.modalstone_isshow = false;
      this.picture_isshow = false;
      this.technology_isshow = false;
    },
    // 显示图库模态框
    picture() {
      this.technologynavigation = false;
      this.aboutnavigation = false;
      this.series = false;
      this.about_isshow = false;
      this.modalstone_isshow = false;
      this.picture_isshow = true;
      this.technology_isshow = false;
    },
    // 显示技术支持模态框
    technology() {
      this.technologynavigation = true;
      this.aboutnavigation = false;
      this.series = false;
      this.about_isshow = false;
      this.modalstone_isshow = false;
      this.picture_isshow = false;
      this.technology_isshow = true;
    },
    // 弹窗  系列模态框
    pop_up(msg) {
      this.$notify({
        message: msg,
        duration: this.duration,
      });
    },
    // 点击下载图片
    download() {
      this.pop_up("正在下载....."); //弹窗
      this.$axios({
        url: this.$settings.base_url + "download/",
        method: "post",
        responseType: "blob",
        data: {
          stone_encode: this.stone_encode,
        },
      })
        .then((response) => {
          const blog = new Blob([response.data], { type: "image/png" }); //以png格式接收后端传回来的二进制数据流
          const url = window.URL.createObjectURL(blog); // 以blob格式将数据流转为URL链接
          const a = document.createElement("a"); // 新建一个a标签
          a.download = ""; // 下载文件命名
          a.href = url; // 给与a标签下载链接
          a.click(); // a标签点击
          this.$notify.closeAll();
        })
        .catch((error) => {});
    },
    // 获取当前元素属性
    pic_display(e) {
      this.pic_dispaly = true;
      this.stone_src = e.target.getAttribute("src");
      this.stone_chinaname = e.target.getAttribute("name");
      this.stone_encode = e.target.getAttribute("encode");
    },
    // 展示石材详细信息
    pic_detail(e) {
      this.$router.push({
          path: "/stones/details",
          query: {
          srcgq: e.target.getAttribute("srcgq"),
            name: e.target.getAttribute("name"),
          },
      }).catch(err=>{console.log(err)
});
    },
    //鼠标滑动导航栏系列处，请求回石材缩略图
    stone_show() {
      this.quartzstone_click();
      this.whitecolor_click();
      this.$axios({
        url: this.$settings.base_url + "stoneinfo/",
        method: "post",
        data: {
          stone_type: "石英石",
          stone_color: "白色",
        },
      })
        .then((response) => {
          this.stone_info = response.data.data;
        })
        .catch((error) => {});
    },
    // 查询石材系列
    select_stone() {
      this.$axios({
        url: this.$settings.base_url + "stoneinfo/",
        method: "post",
        data: {
          stone_type: this.stone_type,
          stone_color: this.stone_color,
        },
      })
        .then((response) => {
          this.stone_info = response.data.data;
        })
        .catch((error) => {});
    },

    // 向父组件发射清除所有模态框事件
    mouseout() {
      this.$emit("clearwhite", "clearwhite");
    },
    //石英石显色
    quartzstone_click() {
      this.quartz_stone = true;
      this.terrazzo = false;
      this.clay = false;
      this.granite = false;
       this.quartz = false;
      this.stone_type = "石英石";
    },
    //水磨石显色
    terrazzo_click() {
      this.terrazzo = true;
      this.clay = false;
      this.quartz_stone = false;
      this.granite = false;
       this.quartz = false;
      this.stone_type = "水磨石";
    },
    // 岗石显色
    granite_click() {
      this.granite = true;
      this.clay = false;
      this.terrazzo = false;
      this.quartz_stone = false;
       this.quartz = false;
      this.stone_type = "岗石";
    },
    // 泥客石显色
    clay_click() {
      this.clay = true;
      this.granite = false;
      this.terrazzo = false;
      this.quartz_stone = false;
      this.quartz = false;
      this.stone_type = "泥客石";
    },
    // 石英砖显色
    quartz_click() {
      this.quartz = true;
       this.clay = false;
      this.granite = false;
      this.terrazzo = false;
      this.quartz_stone = false;
      this.stone_type = "石英砖";
    },
    // 白色系显色
    whitecolor_click() {
      this.BlackColor = false;
      this.YellowColor = false;
      this.WhiteColor = true;
      this.GrayColor = false;
      this.stone_color = "白色";
    },
    // 灰色系显色
    gray_color() {
      this.BlackColor = false;
      this.YellowColor = false;
      this.WhiteColor = false;
      this.GrayColor = true;
      this.stone_color = "灰色";
    },
    // 黄色系显色
    yellow_color() {
      this.BlackColor = false;
      this.YellowColor = true;
      this.WhiteColor = false;
      this.GrayColor = false;
      this.stone_color = "黄色";
    },
    // 黑色系显色
    black_color() {
      this.BlackColor = true;
      this.YellowColor = false;
      this.WhiteColor = false;
      this.GrayColor = false;
      this.stone_color = "黑色";
    },
    // 点击图库，跳转页面
    picture_click() {
      this.$router.push({
        path: "/pictures/",
      });
    },
    // 点击关于系列，跳转页面
    modal_click() {
      this.$router.push({
        path: "/",
      });
    },
    // 点击技术支持，跳转页面
    technology_click() {
      this.$router.push({
        path: "/technology/",
      });
    },
    // 点击关于XX，跳转页面
    about_click() {
      console.log(this.$router.push);
      this.$router.push({
        path: "/abouts/",
      });
    },
    // 点击技术支持 安装维护，跳转页面
    install_click() {
      this.$router.push({
        path: "/technology/install",
      });
    },
    // 点击项目案例，跳转页面
    case_click() {
      this.$router.push({
        path: "/technology/case",
      });
    },
    // 点击证书，跳转页面
    certificate_click() {
      this.$router.push({
        path: "/technology/certificate",
      });
    },
    // 向后端请求石材信息，返回石材信息且带参跳转新页面
    func() {
      this.$axios({
        url: this.$settings.base_url + "stoneselect/",
        method: "post",
        data: {
          stone_title: this.stone_title,
        },
      })
        .then((response) => {
          this.$router.push({
            path: "stones/",
            query: { msg: response.data, image: response.data.image },
          });
        })
        .catch((error) => {});

    },
  },
};
</script>

<style scoped>
.series {
  background-color: RGB(204, 204, 204);
}
.technologynavigation {
  background-color: RGB(204, 204, 204);
}
.aboutnavigation {
  background-color: RGB(204, 204, 204);
}

.navigation {
  line-height: 80px;
  height: 80px;
  float: left;
}
.pic_icon {
  background-color: RGB(135, 135, 135);
  margin-right: 0.03%;
  width: 33%;
  line-height: 250px;
  float: left;
}
#pic {
  background-color: RGB(135, 135, 135);
}
.icon {
  display: inline-block;
  margin-left: 60px;
}
.text {
  height: 70px;
  color: azure;
}
.brand_icon {
  margin-top: 30px;
  height: 50px;
}
.head_pic {
  height: 60px;
}
.modal_pic {
  font-size: 1.5em;
  height: 150px;
}
#span {
  margin-right: 30px;
}
#modal {
  background-color: RGB(135, 135, 135);
  float: left;
  height: 150px;
  overflow: auto;
  margin-right: 0.04%;
}
.stone-type {
  color: azure;
  margin-left: 80%;
  margin-top: 13px;
  font-size: 1em;
}
.stone-color {
  color: azure;
  margin-left: 60%;
  margin-top: 13px;
  font-size: 1em;
}
.modal_type {
  width: 30.46%;
}
.modal_color {
  width: 10.46%;
}
.modal_pic {
  width: 23.46%;
}
.modal_details {
  width: 35.46%;
}
.quartz_stone {
  color: red;
}
.quartz {
  color: red;
}

.clay {
  color: red;
}
.terrazzo {
  color: red;
}
.granite {
  color: red;
}
.WhiteColor {
  color: red;
}
.GrayColor {
  color: red;
}
.YellowColor {
  color: red;
}
.BlackColor {
  color: red;
}
.home_pic {
  margin: 5px;
}
.pic_dispaly {
  width: 100px;
  height: 100px;
  margin-top: 4%;
  margin-left: -80%;
}
.font_display {
  color: azure;
  margin-left: -30%;
  margin-top: -10%;
}
.el-menu-item {
  height: 500px;
  line-height: 500px;
}
</style>
